import cssVars from 'css-vars-ponyfill';

cssVars({
	variables: {
		'primary': '1, 93, 169',
		'primary-dark': '1, 65, 117',
		'accent': '223, 1, 69',
		'accent-plus': '255, 255, 255',
	},
});
